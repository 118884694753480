import { get } from 'lodash';
import { Service } from './type';

const localPorts: any = {
  auth: 3120,
  'brand/read': 3080,
  'brand/write': 3082,
  'campaign/read': 3022,
  'campaign/write': 3020,
  'completion/read': 3085,
  'completion/write': 3083,
  message: 3025,
  'mission/read': 3090,
  'mission/write': 3099,
  'notification/read': 3030,
  'pos/read': 3102,
  'pos/write': 3101,
  'push/notification': 3026,
  'report/read': 3032,
  'reward/read': 3024,
  'reward/write': 3027,
  'store/read': 3071,
  'store/write': 3070,
  'user/read': 3094,
  'user/write': 3093,
  'user-wallet/read': 3086,
  'user-wallet/write': 3087,
  'admin-portal': 3010,
  'queue/api': 3095,
  integration: 3103,
  import: 3109,
};

let forceLocalServices: Service[] = [];

const env = get(process.env, 'REACT_APP_ENV', 'dev');

if (process.env.NODE_ENV === 'development') {
  forceLocalServices = [
    'admin-portal',
    // 'completion/read',
    // 'campaign/write',
    // 'store/read',
    // 'user/write',
    // 'user-wallet/read',
    // 'brand/read',
    // 'brand/write',
    // 'import',
  ];
}

export const getServiceUrl = (serviceId: Service) => {
  if (env === 'local' || forceLocalServices.includes(serviceId)) {
    const localPort = localPorts[serviceId] || 3000;
    return `http://localhost:${localPort}`;
  }

  let serviceUrl = `https://apis.endvr.io/${serviceId}`;
  if (env !== 'production') {
    serviceUrl = `https://apis.${env}.endvr.io/${serviceId}`;
  }

  return serviceUrl;
};
