import { httpPut, httpGet, httpPost, handleError } from './util/axios';
import { Store } from '@/types';

export const getStoreDetails = async (
  storeId: string,
): Promise<{ store: Store }> => {
  return httpGet('store/read', `admin/${storeId}/details`);
};

export const getStoreFormData = async (storeId: string) => {
  return httpGet('store/read', `admin/${storeId}/form-data`);
};

export const updateStore = async (storeId: string, data: any) => {
  try {
    return (await httpPut('store/write', `admin/${storeId}/update`, data)) as
      | { status: false; message: string }
      | { status: 'partial'; storeId: string; message: string }
      | { status: true; storeId: string };
  } catch (error) {
    handleError(error);
  }
};

export const createDraftStore = async () => {
  return httpPost('store/write', `admin/create-draft`, {});
};

export const syncStoresBrandsForRange = async (
  storeId: string,
  brandId: string,
  startDate: Date | null,
  endDate: Date | null,
) => {
  try {
    return httpPost('store/write', `admin/update-store-brands-date-range`, {
      storeId,
      brandId,
      startDate,
      endDate,
    });
  } catch (error) {
    return { status: false, message: 'Something went wrong' };
  }
};

/** Used for getting the ID and name of all stores when changing a mobile user's
 * store */
export const getAllStores = async () => {
  try {
    return (await httpGet('store/read', `admin/get-all`)) as {
      id: string;
      name: string;
    }[];
  } catch (error) {
    handleError(error);
    return null;
  }
};

export type UpdateBrandsAssignmentAction = 'approve' | 'block' | 'unlink';

export const updateBrandsAssignment = async ({
  action,
  storeId,
  brandIds,
}: {
  action: UpdateBrandsAssignmentAction;
  storeId: string;
  brandIds: string[];
}) => {
  try {
    return (await httpPost('store/write', 'admin/update-brands-assignment', {
      action,
      storeId,
      brandIds,
    })) as
      | {
          status: false;
          message: string;
        }
      | {
          status: boolean;
          success: number;
          failed: number;
        };
  } catch (error) {
    handleError(error);
    return null;
  }
};
