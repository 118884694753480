import BrandPickerModal from '@/components/BrandPickerModal';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useTable } from '@/hooks';
import {
  getImportFileDetails,
  importSingleStore,
  importStores,
  setSimilarStores,
  updateFileImportedStoresStatus,
} from '@/services/import.service';
import { IAddress } from '@/types';
import { parseGoogleResult } from '@/utils/address';
import { PlusSquareOutlined, ReloadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Spin,
  Table,
  Tag,
  Typography,
  message,
  notification,
} from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { get } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { When } from 'react-if';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DataContainer } from '../List/styles';
import { BrandCell } from './BrandCell';
import { Map } from './Map';
import { BrandsContainer } from './styles';

export type StoreProps = {
  id: string;
  name: string;
  conflictStores: {
    id: string;
    name: string;
    /** This will only be undefined for old processed stores */
    calcDistance?: number;
    /** This will only be undefined for old processed stores */
    address?: { formattedAddress: string };
    kpi?: {
      users?: number;
      brands?: number;
    };
    /** This will only be undefined for old processed stores */
    scoreByName?: number;
    /** This will only be undefined for old processed stores */
    scoreByAddress?: number;
    /** This will only be undefined for old processed stores */
    scoreByDistance?: number;
    /** This will only be undefined for old processed stores */
    scoreByTotalUsers?: number;
    /** This will only be undefined for old processed stores */
    averageScore?: number;
  }[];
  /** This will be undefined for old processed stores and for stores that don't
   * have any possible conflicts */
  bestAverageScore?: number;
  createdAt: string;
  hasConflict: boolean;
  updatedAt: string;
  lat: number;
  lng: number;
  /** This will be undefined when the store is not yet processed or when the
   * address was not found when processing */
  address?: IAddress;
  location: [number, number] | null;
  status:
    | 'not_started'
    | 'pending'
    | 'processing'
    | 'error'
    | 'address_not_found'
    | 'take_look'
    // TODO: Remove this status after making sure that no imported store has it
    | 'duplicated_on_list'
    | 'looks_good';
  payload: { storeName: string; storeAddress: string };
  addressProcessed: boolean;
  action?: 'connected_existing' | 'created' | 'importing';
};

type ActionFilter = (typeof actionFilters)[number];

/**
 * The value `all` is not actually used by the back-end, it's just that sending
 * any value other than `lowScore`, `mediumScore` or `highScore` will return all
 * stores.
 */
type ScoreFilter =
  | 'all'
  | 'addressNotFound'
  | 'lowScore'
  | 'mediumScore'
  | 'highScore'
  | 'noConflict';

type ImportedStoreModalData = Pick<
  StoreProps,
  'id' | 'name' | 'status' | 'action' | 'address' | 'conflictStores'
>;

const googleKey = get(process.env, 'REACT_APP_GOOGLE_API_KEY', '');

const refreshDataMessages = {
  button: 'Refresh Data',
  title: 'Refresh Data?',
  description: {
    singular:
      'This action will trigger a request to refresh the data of this store (possible conflicting stores, kpis and status). Are you sure?',
    plural:
      'This action will trigger a request to refresh the data of all these stores (possible conflicting stores, kpis and status). Are you sure?',
  },
};

const actionFilters = [
  'notImported',
  'connected_existing',
  'created',
  'importing',
] as const;

// TODO: Add audit
const DealerImportManage: FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const [brandsToConnectWith, setBrandsToConnectWith] = useState<any[]>([]);
  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);
  const [importedStoreModalData, setImportedStoreModalData] =
    useState<ImportedStoreModalData | null>(null);
  const [selectedStores, setSelectedStores] = useState<StoreProps[]>([]);

  const [filters, setFilters] = useState<{
    text: string;
    action: readonly ActionFilter[];
    score: ScoreFilter;
  }>({
    text: '',
    action: actionFilters,
    score: 'all',
  });

  const { tableProps, refresh } = useTable<StoreProps>({
    service: 'import',
    path: `admin/store-list`,
    params: {
      fileId: id,
      filters,
    },
    perPage: 10,
  });
  const { data: importFileDetails, refetch: refetchImportFileDetails } =
    useQuery({
      queryKey: ['import-file-details', id],
      queryFn: async () => {
        const result = await getImportFileDetails(id);
        if (!result.status) {
          throw new Error(result.message);
        }
        return result.data;
      },
    });

  const idsOfBrandsToConnectWith = useMemo(() => {
    return brandsToConnectWith.map((brand) => brand.id);
  }, [brandsToConnectWith]);

  const handleBrandLink = (selectedBrands: any[]) => {
    const allBrands = [...brandsToConnectWith, ...selectedBrands];
    setBrandsToConnectWith(allBrands);
  };

  const removeBrand = (brandId: string) => {
    setBrandsToConnectWith((brands) =>
      brands.filter((brand) => brand.id !== brandId),
    );
    console.log('removeBrand', brandId);
  };

  const handleImportStores = async (
    strategy: Parameters<typeof importStores>[0]['strategy'],
  ) => {
    const { status } = await importStores({
      brandIds: idsOfBrandsToConnectWith,
      storeObjects: selectedStores.map((store) => ({
        importedStoreId: store.id,
      })),
      strategy,
    });

    if (status) {
      // Clear the selected stores
      setSelectedStores([]);

      message.success('Stores were successfully sent to be processed');
      refresh();

      return;
    }

    message.error('Failed to send stores to be processed');
  };

  const updateStoresStatus = () => {
    updateFileImportedStoresStatus(id);
    refetchImportFileDetails();
    message.success('Update store data request sent');
    setTimeout(() => {
      refresh();
    }, 2000);
  };

  function onUpdateStoreAddress() {
    setImportedStoreModalData(null);
    // Refetch related data
    refetchImportFileDetails();
    refresh();
  }

  function onImportStore() {
    setImportedStoreModalData(null);
    // Refetch related data
    refetchImportFileDetails();
    refresh();
  }

  return (
    <Layout>
      <PageHeader title="Dealer Import Detail">
        <When condition={!!tableProps.dataSource}>
          <Flex gap="small">
            <Popconfirm
              title={refreshDataMessages.title}
              description={refreshDataMessages.description.plural}
              okText="Yes"
              cancelText="No"
              onConfirm={updateStoresStatus}
            >
              <Button icon={<ReloadOutlined />}>
                {refreshDataMessages.button}
              </Button>
            </Popconfirm>

            <Button
              onClick={() => setIsBrandModalOpen(true)}
              type="default"
              icon={<PlusSquareOutlined />}
            >
              Add Brand to be Linked
            </Button>

            <Popconfirm
              title="Are you sure?"
              description="This will create new stores for all selected stores, even if there are similar stores in our database. Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleImportStores('createNew')}
            >
              <Button
                disabled={!brandsToConnectWith.length || !selectedStores.length}
                type="primary"
              >
                Create New Stores
              </Button>
            </Popconfirm>

            <Popconfirm
              overlayStyle={{ maxWidth: '30rem' }}
              title="Are you sure?"
              description="This will merge each store with its best match, even if the best match is completely different from the store. If any of the selected stores don't have any similar store, new stores will be created. Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleImportStores('useBestMatch')}
            >
              <Button
                disabled={!brandsToConnectWith.length || !selectedStores.length}
                type="primary"
              >
                Connect Stores to Best Matches
              </Button>
            </Popconfirm>
          </Flex>
          {/* <Flex gap="2px" vertical style={{ marginTop: 8 }}>
            <Typography.Text strong> Progress </Typography.Text>
            <Progress percent={Math.round(progress)} />
          </Flex> */}
        </When>
      </PageHeader>

      {importFileDetails && (
        <ImportFileDetails>
          <div>
            <strong>File name:</strong> {importFileDetails.fileName}
          </div>

          <div>
            <Tag color="error">
              <strong>Stores with address not found:</strong>{' '}
              {importFileDetails.storesWithAddressNotFound}
            </Tag>

            <Tag color="error">
              <strong>Stores with score &lt; 0.5:</strong>{' '}
              {importFileDetails.storesWithLowScore}
            </Tag>

            <Tag color="warning">
              <strong>Stores with score &ge; 0.5 and &lt; 0.75:</strong>{' '}
              {importFileDetails.storesWithMediumScore}
            </Tag>

            <Tag color="success">
              <strong>Stores with score &ge; 0.75:</strong>{' '}
              {importFileDetails.storesWithHighScore}
            </Tag>
            <Tag color="success">
              <strong>Stores with no conflicts:</strong>{' '}
              {importFileDetails.storesWithNoConflict}
            </Tag>
          </div>
        </ImportFileDetails>
      )}

      <BrandsContainer>
        {brandsToConnectWith.map((brand) => (
          <BrandCell
            key={brand.id}
            brand={brand}
            onRemove={(brandId) => removeBrand(brandId)}
          />
        ))}
      </BrandsContainer>

      <FiltersContainer>
        <Input.Search
          placeholder="Search by store name or address"
          allowClear
          onSearch={(value) => {
            setFilters((currentValue) => ({ ...currentValue, text: value }));
          }}
        />

        <Select
          style={{ minWidth: '20rem', fontSize: '0.625rem' }}
          mode="multiple"
          value={filters.action}
          options={
            [
              { label: 'Not Imported Yet', value: 'notImported' },
              {
                label: 'Connected to Existing Store',
                value: 'connected_existing',
              },
              { label: 'Created', value: 'created' },
              { label: 'Importing', value: 'importing' },
            ] satisfies (BaseOptionType & { value: ActionFilter })[]
          }
          onChange={(value) => {
            setFilters((filters) => ({ ...filters, action: value }));
          }}
        />

        <Select
          style={{ minWidth: '13.5rem', textAlign: 'center' }}
          value={filters.score}
          options={
            [
              { label: 'All scores', value: 'all' },
              { label: 'Address not found', value: 'addressNotFound' },
              { label: 'Score < 0.5', value: 'lowScore' },
              { label: 'Score ≥ 0.5 and < 0.75', value: 'mediumScore' },
              { label: 'Score ≥ 0.75', value: 'highScore' },
              { label: 'No conflicts', value: 'noConflict' },
            ] satisfies (BaseOptionType & { value: ScoreFilter })[]
          }
          onChange={(value) => {
            setFilters((filters) => ({ ...filters, score: value }));
          }}
        />
      </FiltersContainer>

      <DataContainer>
        <Table
          {...tableProps}
          rowKey="id"
          tableLayout="fixed"
          className="clickable"
          rowSelection={{
            type: 'checkbox',
            getCheckboxProps: (record) => ({
              /* TODO: Should we allow to select stores that were created or
              connected with an existing store? */
              disabled: !canImportStore(record),
            }),
            selectedRowKeys: selectedStores.map((store) => store.id),
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedStores(selectedRows);
            },
          }}
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
          }}
        >
          <Table.Column
            title="Name"
            dataIndex="name"
            key="name"
            sorter
            render={(text, record: StoreProps) => (
              <Typography.Text>{record.name}</Typography.Text>
            )}
          />
          <Table.Column
            title="Address Sent"
            dataIndex="aiAddress"
            key="payload"
            render={(text, record: StoreProps) => (
              <Typography.Text>{record.payload.storeAddress}</Typography.Text>
            )}
          />
          <Table.Column
            title="Address Processed"
            dataIndex="address"
            key="address"
            render={(text, record: StoreProps) => {
              if (!record.addressProcessed) {
                return <Tag color="warning">Not Processed Yet</Tag>;
              }

              if (!record.address) {
                return <Tag color="error">Address Not Found</Tag>;
              }

              return (
                <Typography.Text>
                  {record.address.formattedAddress}
                </Typography.Text>
              );
            }}
          />
          <Table.Column
            title="Situation"
            dataIndex="action"
            key="action"
            render={(text, record: StoreProps) => {
              const colorAndLabelByAction = {
                connected_existing: {
                  color: 'green',
                  label: 'Connected to Existing Store',
                },
                created: { color: 'blue', label: 'Created' },
                importing: { color: 'warning', label: 'Importing' },
              } satisfies Record<
                NonNullable<StoreProps['action']>,
                { color: string; label: string }
              >;

              const { color, label } =
                record.action !== undefined
                  ? colorAndLabelByAction[record.action]
                  : { color: 'default', label: 'Not Imported Yet' };

              return <Tag color={color}>{label}</Tag>;
            }}
          />
          <Table.Column
            title="Best Average Score"
            dataIndex="bestAverageScore"
            key="bestAverageScore"
            // TODO: Add sorting
            render={(text, record: StoreProps) => (
              <Tag color={getScoreColor(record.bestAverageScore)}>
                {formatScore(record.bestAverageScore, undefined)}
              </Tag>
            )}
          />
          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(text, record: StoreProps) => {
              // Do not allow editing stores that are being imported
              if (record.action === 'importing') {
                return null;
              }

              return (
                <Button
                  onClick={() => {
                    setImportedStoreModalData({
                      id: record.id,
                      name: record.name,
                      status: record.status,
                      action: record.action,
                      address: record.address,
                      conflictStores: record.conflictStores,
                    });
                  }}
                >
                  Edit
                </Button>
              );
            }}
          />
        </Table>
        <When condition={!tableProps.loading}>
          <div className="map">
            <Map stores={tableProps.dataSource} />
          </div>
        </When>
      </DataContainer>
      <BrandPickerModal
        open={isBrandModalOpen}
        onClose={() => setIsBrandModalOpen(false)}
        selected={idsOfBrandsToConnectWith}
        multiple
        onSelect={handleBrandLink}
        hideInactive={false}
        removeSelected={true}
        onSelectBrands={(brands) => handleBrandLink(brands)}
      />

      {/* TODO: Disable this modal if the store was already created or connected
      to an existing store. Also disable the actions within the modal in the
      back-end */}
      <ImportedStoreModal
        data={importedStoreModalData}
        idsOfBrandsToConnectWith={idsOfBrandsToConnectWith}
        scoreWeights={importFileDetails?.scoreWeights}
        onUpdateStoreAddress={onUpdateStoreAddress}
        onImportStore={onImportStore}
        onCancel={() => setImportedStoreModalData(null)}
      />
    </Layout>
  );
};

function ImportedStoreModal({
  data,
  idsOfBrandsToConnectWith,
  scoreWeights,
  onUpdateStoreAddress,
  onImportStore,
  onCancel,
}: {
  data: ImportedStoreModalData | null;
  idsOfBrandsToConnectWith: string[];
  scoreWeights: Record<string, number> | undefined;
  onUpdateStoreAddress: () => void;
  onImportStore: () => void;
  onCancel: () => void;
}) {
  const isModalOpen = useMemo(() => data !== null, [data]);
  const [newAddress, setNewAddress] = useState<IAddress | null>(null);
  const [selectedExistingStoreId, setSelectedExistingStoreId] = useState<
    string | null
  >(null);

  const {
    mutate: handleUpdateStoreAddress,
    isPending: isUpdatingStoreAddress,
  } = useMutation({
    mutationFn: async ({ address }: { address: IAddress }) => {
      if (!data) return;
      const result = await setSimilarStores({
        id: data.id,
        name: data.name,
        /* When using the same address, we won't update the address, only the
        possible conflicting stores */
        address,
      });
      if (!result.status) {
        throw new Error(result.message);
      }
    },
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description:
          'Store address was successfully updated. Check its status in the list to see if it has possible conflicts with other stores.',
      });
      onUpdateStoreAddress();
    },
    onError: (error) => {
      notification.error({ message: 'Error', description: error.message });
    },
  });

  const { mutate: handleImportStore, isPending: isImportingStore } =
    useMutation({
      mutationFn: async ({
        existingStoreId,
      }: {
        existingStoreId: string | undefined;
      }) => {
        if (!data) return;

        const result = await importSingleStore({
          brandIds: idsOfBrandsToConnectWith,
          storeObject: {
            importedStoreId: data.id,
            /* If the store ID is not set, it creates a new store, otherwise, it
            sets the imported store as the one with the given ID */
            storeId: existingStoreId,
          },
        });
        if (!result.status) {
          throw new Error(result.message);
        }
      },
      onSuccess: () => {
        notification.success({
          message: 'Success',
          description: 'Stores were successfully sent to be processed',
        });
        onImportStore();
      },
      onError: (error) => {
        notification.error({ message: 'Error', description: error.message });
      },
    });

  const hasSelectedBrands = idsOfBrandsToConnectWith.length > 0;
  const isLoading = isUpdatingStoreAddress || isImportingStore;
  const isSetAsExistingStoreDisabled =
    !data ||
    !canImportStore(data) ||
    selectedExistingStoreId === null ||
    !hasSelectedBrands ||
    isLoading;
  const isCreateNewStoreDisabled =
    !data || !canImportStore(data) || !hasSelectedBrands || isLoading;

  // Reset the states when the modal is closed
  useEffect(() => {
    if (!isModalOpen) {
      setNewAddress(null);
      setSelectedExistingStoreId(null);
    }
  }, [isModalOpen]);

  return (
    <Modal
      title="Store Details"
      open={isModalOpen}
      width={768}
      footer={null}
      destroyOnClose
      onCancel={onCancel}
    >
      {data !== null && (
        <StoreAddressAndConflicts>
          <div>
            <strong style={{ fontSize: '1rem' }}>Update Store Address</strong>

            <p>
              <strong>Store Name:</strong> {data.name}
            </p>

            {data.address !== undefined ? (
              <>
                <p>
                  Changing the address will update the possible conflicting
                  stores.
                </p>

                <p>
                  <strong>Current Address:</strong>{' '}
                  {data.address.formattedAddress}
                </p>
              </>
            ) : (
              <p>
                Address not found, please search and select an address for the
                store input below so that we can check for conflicting stores in
                our database.
              </p>
            )}

            {newAddress?.formattedAddress && (
              <p>
                <strong>Selected Address:</strong> {newAddress.formattedAddress}
              </p>
            )}

            <ReactGoogleAutocomplete
              apiKey={googleKey}
              style={{ width: '100%' }}
              placeholder="Search address"
              onPlaceSelected={(place) => {
                const address = parseGoogleResult(place);
                setNewAddress(address);
              }}
              options={{ types: ['address'] }}
            />

            <Popconfirm
              title="Update store address?"
              description="Are you sure you want to update the store address?"
              okText="Yes"
              cancelText="No"
              disabled={!newAddress || isLoading}
              onConfirm={() => {
                if (!newAddress) return;
                handleUpdateStoreAddress({ address: newAddress });
              }}
            >
              <Button type="primary" disabled={!newAddress || isLoading}>
                Update Store Address
              </Button>
            </Popconfirm>
          </div>

          <Divider type="vertical" />

          <div>
            <strong style={{ fontSize: '1rem' }}>
              Set as Existing Store or Create New Store
            </strong>

            <Typography.Paragraph
              ellipsis={{ expandable: 'collapsible', defaultExpanded: true }}
            >
              Scores determine how similar the imported store is to the existing
              ones. <strong>Maximum</strong> score is 1.{' '}
              <strong>Minimum</strong> score is 0. Stores are sorted by average
              score, from the best to the worst. Some scores have{' '}
              <strong>different weights</strong> indicated in parentheses.
            </Typography.Paragraph>

            <Popconfirm
              title={refreshDataMessages.title}
              description={refreshDataMessages.description.singular}
              okText="Yes"
              cancelText="No"
              disabled={!data.address || isLoading}
              onConfirm={() => {
                if (!data.address) return;
                handleUpdateStoreAddress({ address: data.address });
              }}
            >
              <Button
                icon={<ReloadOutlined />}
                disabled={!data.address || isLoading}
              >
                {refreshDataMessages.button}
              </Button>
            </Popconfirm>

            <Radio.Group
              value={selectedExistingStoreId}
              onChange={(event) => {
                setSelectedExistingStoreId(event.target.value);
              }}
            >
              <StoreConflicts>
                {data.conflictStores.map((store) => (
                  <Radio key={store.id} value={store.id}>
                    <StoreConflictItem>
                      <span>
                        <strong>ID: </strong>
                        <Typography.Link
                          href={`/store/${store.id}/manage`}
                          target="_blank"
                          ellipsis
                          copyable
                        >
                          {store.id}
                        </Typography.Link>
                      </span>
                      <span>
                        <strong>Name:</strong> {store.name}{' '}
                        <Tag color={getScoreColor(store.scoreByName)}>
                          Score:{' '}
                          {formatScore(store.scoreByName, scoreWeights?.name)}
                        </Tag>
                      </span>

                      <span>
                        <strong>Address:</strong>{' '}
                        {store.address?.formattedAddress ?? 'Unknown'}{' '}
                        <Tag color={getScoreColor(store.scoreByAddress)}>
                          Score:{' '}
                          {formatScore(
                            store.scoreByAddress,
                            scoreWeights?.address,
                          )}
                        </Tag>
                      </span>

                      <span>
                        <strong>Distance:</strong>{' '}
                        {formatDistance(store.calcDistance)}{' '}
                        <Tag color={getScoreColor(store.scoreByDistance)}>
                          Score:{' '}
                          {formatScore(
                            store.scoreByDistance,
                            scoreWeights?.distance,
                          )}
                        </Tag>
                      </span>

                      <span>
                        <strong>Kpi:</strong> {store.kpi?.users ?? 0} Users,{' '}
                        {store.kpi?.brands ?? 0} Brands{' '}
                        <Tag color={getScoreColor(store.scoreByTotalUsers)}>
                          Score:{' '}
                          {formatScore(
                            store.scoreByTotalUsers,
                            scoreWeights?.totalUsers,
                          )}
                        </Tag>
                      </span>

                      <Tag color={getScoreColor(store.averageScore)}>
                        Average Score:{' '}
                        {formatScore(store.averageScore, undefined)}
                      </Tag>
                    </StoreConflictItem>
                  </Radio>
                ))}
              </StoreConflicts>
            </Radio.Group>

            <Typography.Text style={{ marginTop: '0.5rem' }} type="warning">
              <strong>NOTE:</strong> Setting the imported store as one of the
              existing stores OR creating a new one will already connect the
              store to the selected brands.
            </Typography.Text>

            <Popconfirm
              title="Set imported store as existing store?"
              description={() => {
                const conflictingStore = data.conflictStores.find(
                  (store) => store.id === selectedExistingStoreId,
                );
                return (
                  <ImportStorePopconfirmDescription>
                    <p>
                      Are you sure you want to set the imported store as the
                      following existing store?
                    </p>
                    <span>
                      <strong>Name:</strong> {conflictingStore?.name}
                    </span>
                    <span>
                      <strong>Address:</strong>{' '}
                      {conflictingStore?.address?.formattedAddress}
                    </span>
                    <span>
                      <strong>Kpi:</strong> {conflictingStore?.kpi?.users ?? 0}{' '}
                      Users, {conflictingStore?.kpi?.brands ?? 0} Brands
                    </span>
                  </ImportStorePopconfirmDescription>
                );
              }}
              okText="Yes"
              cancelText="No"
              disabled={isSetAsExistingStoreDisabled}
              onConfirm={() => {
                if (!selectedExistingStoreId) return;
                handleImportStore({
                  existingStoreId: selectedExistingStoreId,
                });
              }}
            >
              {/* TODO: Add tooltip for why this button is disabled */}
              <Button type="primary" disabled={isSetAsExistingStoreDisabled}>
                Set as Existing Selected Store
              </Button>
            </Popconfirm>

            <Popconfirm
              title="Create new store?"
              description="Are you sure you want to create a new store?"
              okText="Yes"
              cancelText="No"
              disabled={isCreateNewStoreDisabled}
              onConfirm={() => {
                handleImportStore({ existingStoreId: undefined });
              }}
            >
              {/* TODO: Add tooltip for why this button is disabled */}
              <Button disabled={isCreateNewStoreDisabled}>
                Create New Store
              </Button>
            </Popconfirm>
          </div>
        </StoreAddressAndConflicts>
      )}

      {isLoading && (
        <ModalLoadingOverlay>
          <Spin />
        </ModalLoadingOverlay>
      )}
    </Modal>
  );
}

function formatScore(score: number | undefined, weight: number | undefined) {
  if (score === undefined) return '?';
  return `${score.toFixed(2)}` + (weight ? ` (*${weight})` : '');
}

function getScoreColor(score: number | undefined) {
  if (score === undefined) return 'default';

  if (score < 0.5) return 'error';
  if (score < 0.75) return 'warning';
  return 'success';
}

function formatDistance(distance: number | undefined) {
  if (distance === undefined) return '?';
  return `${distance.toFixed(2)} m`;
}

function canImportStore(
  store: Pick<StoreProps, 'status' | 'action' | 'address'>,
) {
  /* Do not allow to importing stores that were not processed yet or whose
  address was not found or that are being imported */
  return (
    store.status !== 'pending' &&
    store.address !== undefined &&
    store.action !== 'importing'
  );
}

const ImportFileDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const FiltersContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const StoreAddressAndConflicts = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 1rem;

  & > div:not(.ant-divider) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .ant-divider {
    height: 100%;
  }

  p {
    margin: unset;
  }

  @media not all and (min-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;

    .ant-divider {
      display: none;
    }
  }
`;

const StoreConflicts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 20rem;
  overflow: auto;

  .ant-radio-wrapper {
    padding: 0.5rem;
    border: 1px solid #d9d9d9;
  }
`;

const StoreConflictItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ImportStorePopconfirmDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p {
    margin: unset;
  }
`;

const ModalLoadingOverlay = styled.div`
  background-color: rgb(255 255 255 / 0.5);
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default DealerImportManage;
