import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit } from '@/hooks';
import {
  getCountries,
  saveCountryPreference,
} from '@/services/country.service';
import { useSession } from '@/store';
import { formatMoney } from '@/utils';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Switch,
  Table,
  Tag,
} from 'antd';
import { upperCase } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { IoCogSharp } from 'react-icons/io5';

type Country = {
  id: string;
  name: string;
  currency: string;
  incomeLimitActive: boolean;
  incomeLimit: number;
  taxIncomeFileUrl: string;
  countryCode: string;
  region: string;
  subRegion: string;
  continent: string;
};

const UpdateComponent: FC<{ country: Country; onRefresh: () => void }> = ({
                                                                            country,
                                                                            onRefresh,
                                                                          }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const [data, setData] = useState<Country>(country);

  const isValid = useMemo(() => {
    const currency = String(data.currency).toUpperCase();
    const taxIncomeFileUrl = data.taxIncomeFileUrl || '';
    return (
      !data.incomeLimitActive ||
      (data.incomeLimitActive &&
        data.incomeLimit > 0 &&
        currency.length > 0 &&
        taxIncomeFileUrl.length > 0)
    );
  }, [
    data.incomeLimit,
    data.incomeLimitActive,
    data.currency,
    data.taxIncomeFileUrl,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      incomeLimit: country.incomeLimit,
      incomeLimitActive: country.incomeLimitActive,
      currency: country.currency || 'USD',
      taxIncomeFileUrl: country.taxIncomeFileUrl || '',
    });
  }, [isOpen]);

  const audit = useAudit({
    resourceName: 'country-preference',
  });

  const onFinish = async (values: any) => {
    setIsLoading(true);

    await saveCountryPreference({
      countryId: country.id,
      incomeLimitActive: data.incomeLimitActive,
      incomeLimit: data.incomeLimit,
      currency: data.currency || 'USD',
      taxIncomeFileUrl: data.taxIncomeFileUrl || '',
    });

    audit.onUpdate({
      target: 'update-country-income-limit',
      data: {
        countryId: country.id,
        countryName: country.name,
        incomeLimitActive: data.incomeLimitActive,
        incomeLimit: data.incomeLimit,
        currency: data.currency || 'USD',
        taxIncomeFileUrl: data.taxIncomeFileUrl || '',
      },
    });

    setIsLoading(false);
    setIsOpen(false);

    onRefresh();
  };

  return (
    <Popconfirm
      open={isOpen}
      title={
        <span style={{ fontWeight: 600, marginBottom: 20, display: 'block' }}>
          Update income limit for{' '}
          <strong style={{ fontWeight: 700, color: '#6f56bc', fontSize: 16 }}>
            {country.name}
          </strong>
        </span>
      }
      icon={false}
      onCancel={() => setIsOpen(false)}
      okButtonProps={{
        disabled: !isValid,
      }}
      onConfirm={onFinish}
      description={
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{ width: 400 }}
        >
          <Form.Item
            name="incomeLimitActive"
            label="Income Limit Active"
            valuePropName="checked"
          >
            <Switch
              checked={data.incomeLimitActive}
              onChange={(checked: boolean) =>
                setData((prev) => ({
                  ...prev,
                  incomeLimitActive: checked,
                }))
              }
            />
          </Form.Item>

          <Form.Item
            name="currency"
            label="Currency"
            rules={[{ required: data.incomeLimitActive }]}
            help="ISO 4217 currency code, e.g. USD, EUR, GBP"
          >
            <Input
              value={data.currency}
              onChange={(e: any) => {
                const currency = String(e.target.value).toUpperCase();
                setData((prev) => ({
                  ...prev,
                  currency,
                }));

                form.setFieldsValue({
                  currency,
                });
              }}
              style={{ width: '100%' }}
              maxLength={3}
            />
          </Form.Item>
          <br />
          <Form.Item
            name="incomeLimit"
            label="Income Limit"
            rules={[{ required: data.incomeLimitActive }]}
          >
            <InputNumber
              value={data.incomeLimit}
              onChange={(value: number | null) =>
                setData((prev) => ({
                  ...prev,
                  incomeLimit: value || 0,
                }))
              }
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            name="taxIncomeFileUrl"
            label="Tax Income File URL"
            rules={[{ required: data.incomeLimitActive }]}
          >
            <Input
              value={data.taxIncomeFileUrl}
              onChange={(e: any) => {
                const taxIncomeFileUrl = String(e.target.value);
                setData((prev) => ({
                  ...prev,
                  taxIncomeFileUrl,
                }));

                form.setFieldsValue({
                  taxIncomeFileUrl,
                });
              }}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Form>
      }
    >
      <Button
        type="primary"
        onClick={() => setIsOpen(true)}
        icon={<IoCogSharp />}
      >
        Adjust Income
      </Button>
    </Popconfirm>
  );
};

const CountryList = () => {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<Country[]>([]);

  const session = useSession();

  const audit = useAudit({
    resourceName: 'country-list',
  });

  useEffect(() => {
    loadCountries();
    audit.onAccess();
  }, []);

  const loadCountries = async () => {
    setLoading(true);
    const countries = await getCountries();
    setCountries(countries);
    setLoading(false);
  };

  return (
    <>
      <Layout>
        <PageHeader noBackButton title="Countries" />

        <div>
          <Table loading={loading} dataSource={countries} pagination={false}>
            <Table.Column
              title="Code"
              width={100}
              dataIndex="countryCode"
              sorter={(a: Country, b: Country) =>
                a.countryCode.localeCompare(b.countryCode)
              }
            />
            <Table.Column
              title="Country Name"
              dataIndex="name"
              sorter={(a: Country, b: Country) => a.name.localeCompare(b.name)}
            />
            <Table.Column
              title="Continent"
              dataIndex="continent"
              sorter={(a: Country, b: Country) =>
                a.continent.localeCompare(b.continent)
              }
            />
            <Table.Column
              title="Income Limit Active"
              dataIndex="incomeLimitActive"
              align="center"
              render={(_, record: Country) => (
                <Tag
                  color={record.incomeLimitActive ? 'green' : 'default'}
                  style={{ marginBottom: 0 }}
                >
                  {record.incomeLimitActive ? 'Active' : 'Inactive'}
                </Tag>
              )}
            />

            <Table.Column
              title="Income Limit"
              dataIndex="incomeLimit"
              align="center"
              render={(_, record: Country) => (
                <>{formatMoney(record.incomeLimit)}</>
              )}
            />

            <Table.Column
              title="Currency"
              dataIndex="currency"
              align="center"
            />

            {session.hasPermission('countries.edit') && (
              <Table.Column
                title=""
                dataIndex="actions"
                key="actions"
                align="right"
                render={(text: any, record: Country, index: number) => {
                  return (
                    <UpdateComponent
                      country={record}
                      onRefresh={() => {
                        loadCountries();
                      }}
                    />
                  );
                }}
              />
            )}
          </Table>
        </div>
      </Layout>
    </>
  );
};

export default CountryList;